.roadmap-content {
    max-width: 900px;
    padding-top: 30px;
    padding-bottom: 30px;
}

@media only screen and (max-width: 1320px) {
    .roadmap-content {
        text-align: center;
    }
}
