.fw-700 {
    font-weight: 700;
}

.about-header {
    padding-left: 25vw;
    padding-right: 25vw;
    padding-bottom: 42px;
    padding-top: 40px;
    background-color: #F3F3F3;
}

.about-header-title {
    font-size: 48px;
    font-weight: 800;
    text-align: center;
}

.about-header-content {
    font-size: 24px;
    font-weight: 700;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    text-align: center;
    color: #E1987C;
}

.about-content {
    padding-top: 48px;
    padding-bottom: 78px;
    display: flex;
    justify-content: space-between;
}

.about-left-content {
    margin-top: 10px;
    width: 50%;
    /* font-size: 19px; */
}

.about-left-content p span{
    font-weight: bold;
}

.about-right-content {
    padding-top: 30px;
    width: 40%;
}

.about-content-narrow {
    text-align: center;
}

.about-content-middle {
    display: flex;
    justify-content: center;
}

.about-content-middle-wrap {
    width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media only screen and (min-width: 1100px) {
    .about-content {
        display: flex;
    }

    .about-content-narrow {
        display: none;
    }
}

@media only screen and (max-width: 1100px) {
    .about-content {
        display: none;
    }

    .about-content-narrow {
        display: block;
    }
}

.image1 {
    width: 100%;
}

.image2 {
    width: 100%;
    margin-top: 40px;
}

.about-image-title {
    font-size: 16px;
    text-align: center;
}


@media only screen and (min-width: 1100px) {
    .bold1{
        font-size: 20px !important;
        line-height: 30px !important;
    }
}

@media only screen and (max-width: 1100px) {
    .bold2{
        font-size: 24px;
        line-height: 36px;
    }
}

@media only screen and (max-width: 800px) {
    .bold2{
        font-size: 15px;
        font-weight: 600;
    }
}