.collapsible-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
}

.collapsible-header-wrapper :hover {
    cursor: pointer;
}

.extended .collapsible-header-wrapper .collapsible-header {
    color: #0099FF;
}

.collapsibleComponent .collapsible-content{
    font-size: 18px;
}

@media only screen and (max-width: 1320px) {
    .collapsibleComponent .collapsible-content{
        font-size: 14px;
    }
}

@media only screen and (max-width: 1100px) {
    .collapsibleComponent .collapsible-content{
        font-size: 11px;
    }
}