.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 15px;
    padding-top: 15px;
    background-color: white;
    z-index: 1;
}

@media only screen and (max-width: 1320px) {
    .navbar {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 10px;
        padding-top: 10px;
    }
}

@media only screen and (max-width: 540px) {
    .navbar {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.sticky {
    position: fixed;
    top: 0px;
    width: 100%;
}

.name-logo {
    font-size: 32px;
    font-weight: 400;
    text-decoration: none;
    color: black;
}

@media only screen and (max-width: 1320px) {
    .name-logo {
        font-size: 26px;
    }
}

@media only screen and (max-width: 1100px) {
    .name-logo {
        font-size: 20px;
    }
}

@media only screen and (max-width: 834px) {
    .name-logo {
        font-size: 16px;
    }
}

@media only screen and (max-width: 540px) {
    .name-logo {
        font-size: 14px;
    }
}

.fw-600 {
    font-weight: 600 !important;
}

.navbar-item{
    font-size: 16px !important;
}

@media only screen and (max-width: 1320px) {
    .navbar-item{
        font-size: 14px !important;
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
}

@media only screen and (max-width: 1100px) {
    .navbar-item{
        font-size: 12px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        min-width: 50px !important;
    }
}

.color-black {
    color: black !important;
}

.narrow-item {
    display: block !important;
}

.nav-menu-narrow-icon {
    z-index: 10;
    text-align: center;
}

.menu-icon {
    color: black;
}

@media only screen and (max-width: 950px) {
    .nav-menu {
        display: none;
    }
}

@media only screen and (min-width: 950px) {
    .nav-menu {
        display: flex;
    }
}

.nav-menu-narrow-conten {
    position: fixed;
}

.open-menu .nav-menu-narrow-content {
    display: block;
}

.close-menu .nav-menu-narrow-content{
    display: none;
}


