.team-content {
    padding-top: 60px;
    padding-bottom: 60px;
}

.flex-container {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.team-member {
    width: 25%;
}

@media only screen and (max-width: 780px) {
    .team-member {
        width: 33%;
    }
}

@media only screen and (max-width: 540px) {
    .team-member {
        width: 50%;
    }
}