.section-header {
    background-color: #F3F3F3;
    padding-top: 30px;
    padding-bottom: 30px;
}

.section-name {
    font-size: 48px;
    font-weight: 800;
    text-align: center;
}

@media only screen and (max-width: 1320px) {
    .section-name {
        font-size: 36px;
    }    
}

@media only screen and (max-width: 1100px) {
    .section-name {
        font-size: 24px;
    }    
}

.section-description {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #E1987C;
    padding-left: 45px;
    padding-right: 45px;
}

@media only screen and (max-width: 1320px) {
    .section-description {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1100px) {
    .section-description {
        font-size: 16px;
    }
}

@media only screen and (max-width: 800px) {
    .section-description {
        padding-left: 0px;
        padding-right: 0px;
    }
}
