.personal-card {
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%);
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.personal-photo {
    width: 100%;
}

.personal-photo-wrap {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.personal-info-name {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}

.personal-info-role {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
}

.social-media {
    display: flex;
    justify-content: space-evenly;
    padding-top: 12px;
}

.social-media-icon {
    width: 35px;
    height: 35px;
}

@media only screen and (max-width: 1100px) {
    .personal-info-name {
        font-size: 16px;
    }

    .personal-info-role {
        font-size: 14px;
    }

    .social-media-icon {
        width: 30px;
        height: 30px;
    }
}

@media only screen and (max-width: 834px) {
    .personal-info-name {
        font-size: 12px;
    }

    .personal-info-role {
        font-size: 11px;
    }

    .social-media-icon {
        width: 24px;
        height: 24px;
    }
}

@media only screen and (max-width: 780px) {
    .personal-info-name {
        font-size: 16px;
    }

    .personal-info-role {
        font-size: 14px;
    }

    .social-media-icon {
        width: 30px;
        height: 30px;
    }
}

@media only screen and (max-width: 712px) {
    .personal-info-name {
        font-size: 14px;
    }

    .personal-info-role {
        font-size: 12px;
    }

    .social-media-icon {
        width: 24px;
        height: 24px;
    }
}

@media only screen and (max-width: 620px) {
    .personal-info-name {
        font-size: 12px;
    }

    .personal-info-role {
        font-size: 11px;
    }

    .social-media-icon {
        width: 24px;
        height: 24px;
    }
}

@media only screen and (max-width: 540px) {
    .personal-info-name {
        font-size: 20px;
    }

    .personal-info-role {
        font-size: 16px;
    }

    .social-media-icon {
        width: 35px;
        height: 35px;
    }
}

@media only screen and (max-width: 460px) {
    .personal-info-name {
        font-size: 16px;
    }

    .personal-info-role {
        font-size: 14px;
    }

    .social-media-icon {
        width: 30px;
        height: 30px;
    }
}

@media only screen and (max-width: 436px) {
    .personal-info-name {
        font-size: 12px;
    }

    .personal-info-role {
        font-size: 11px;
    }

    .social-media-icon {
        width: 20px;
        height: 20px;
    }
}