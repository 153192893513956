.stats {
    margin-top: 15px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 1320px) {
    .stats {
        margin-bottom: 25px;
    }
}

@media only screen and (max-width: 1100px) {
    .stats {
        margin-bottom: 0px;
        margin-top: 0px;
    }
}

.stats-header {
    text-align: center;
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 25px;
}

@media only screen and (max-width: 1320px) {
    .stats-header {
        font-size: 36px;
        margin-bottom: 18px;
    }
}

@media only screen and (max-width: 1100px) {
    .stats-header {
        font-size: 24px;
        margin-bottom: 18px;
    }
}

.stats-content{    
    justify-content: space-around;
    width: 100%;
    display: flex;
}

@media only screen and (max-width: 1100px) {
    .stats-content{    
        justify-content: space-around;
    }
}

@media only screen and (max-width: 800px) {
    .stats-content{    
        display: block;
    }
}