.contact-icon {
    width: 70px;
    height: 70px;
}

@media only screen and (max-width: 800px) {
    .contact-icon {
        width: 54px;
        height: 54px;
    }
}

.contact-content {
    text-align: center;
}