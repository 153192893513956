.royalty-club-content {
    text-align: left;
    padding-top: 65px !important;
    padding-bottom: 30px !important;
}

.royalty-club-content-text {
    padding-bottom: 30px;
    padding-right: 300px;
}

.royalty-club-content-text span {
    font-weight: 700;
}

@media only screen and (max-width: 1100px) {
    .royalty-club-content {
        text-align: center;
        padding-top: 30px;
    }
    .royalty-club-content-text {
        padding-right: 0px;
    }
}

.RoyaltyClubImg1 {
    width: 100%;
}

.RoyaltyClubImg2 {
    width: 100%;
}

@media only screen and (min-width: 540px) {
    .RoyaltyClubImg1 {
        display: block;
    }
    
    .RoyaltyClubImg2 {
        display: none;
    }
}


@media only screen and (max-width: 540px) {
    .RoyaltyClubImg1 {
        display: none;
    }
    
    .RoyaltyClubImg2 {
        display: block;
    }
}
