.display-nft {
    display: inline-flex;
    flex-wrap: wrap;    
    justify-content: center;
    padding: 30px;
    box-sizing: border-box
}

.nft {
    width: 50%;

}
