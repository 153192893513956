.vision-content {
    padding-top: 40px;
    padding-bottom: 40px;
}

@media only screen and (max-width: 834px) {
    .vision-content {
        text-align: center;
    }

    .vision-content .order {
        text-align: left;
    }
    
    .vision-content p {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

.famous-phrase {
    text-align: center;
    color: #E1987C;
    font-weight: 700;
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 30px;
}

@media only screen and (max-width: 834px) {
    .famous-phrase {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 30px;
    }
}

