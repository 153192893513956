.nft-card {
    text-align: center;
    margin: 30px;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%);
    padding : 5px
}

.nft-image {
    width: 100%;
}

.nft-download-button {
    width: 100%;
    background-color: #E1987C !important;
    color: black !important;
    background: rgba(0, 0, 0, 0.5);
}

.nft-image-wrap {
    position: relative;
}

.nft-image-wrap:hover .nft-card-overlay{
    opacity: 1;
}

.nft-card-overlay {
    position: absolute;
    bottom: 0;
    padding: 15px;
    width: 100%;
    background: rgba(0, 0, 0, 0.5); 
    color: #f1f1f1;
    transition: .5s ease;
    opacity: 0;
    box-sizing: border-box;

}