.portal-content {
    text-align: center;
}

.portal-content-notice p span {
    font-weight: 700;
    font-size: 22px;
}

.portal-info .status .status-content-wrap {
    display: flex;
    justify-content: center;
}

.withdraw-button {
    background-color: #E1987C !important;
    color: black !important;
    font-size: 22px !important;
    width: 100%;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

@media only screen and (max-width: 1100px) {
    .withdraw-button{
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 540px) {
    .withdraw-button{
        font-size: 14px !important;
    }
}