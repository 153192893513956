.status {
    margin-bottom: 20px;
}

/* @media only screen and (max-width: 800px) {
    .status {
        margin-bottom: 20px;
    }
} */

.status-header{
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1100px) {
    .status-header {
        font-size: 14px;
    }
}

.status-content {
    font-size: 36px;
    font-weight: 700;
    padding-top: 11px;
    padding-bottom: 11px;
    width: 250PX;
    height: 70;
    background-color: #E1987C;
    color: white;
    text-align: center;
}

@media only screen and (max-width: 1460px) {
    .status-content {
        font-size: 24px;
    }
}

@media only screen and (max-width: 1100px) {
    .status-content {
        font-size: 18px;
        width: 180PX;
    }
}

@media only screen and (max-width: 800px) {
    .status-content-wrap {
        display: flex;
        justify-content: center;
    }
}

