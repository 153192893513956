html {
    scroll-behavior: smooth;
}

/* * {box-sizing: border-box} */

body {
    font-size: 22px;
    font-family: 'Poppins', sans-serif !important;
}

@media only screen and (max-width: 1320px) {
    body {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1100px) {
    body {
        font-size: 16px;
    }
}

@media only screen and (max-width: 540px) {
    body {
        font-size: 11px;
    }
}

.header {
    background-position: top right;
    font-size: 70px;
    font-weight: 800;
    color: #E1987C;
    text-shadow: 9px 7px 10px rgb(0, 0, 0, 0.7);
    text-align: center;
    background-image: url(../images/Header_Background/MintingsitebackgroundTOP.jpg);
    /* background-image: url(../../../assets/images/Header_Background/MintingsitebackgroundTOP.jpg); */
}

@media only screen and (max-width: 1320px) {
    .header {
        font-size: 60px;
    }
}

@media only screen and (max-width: 1100px) {
    .header {
        font-size: 48px;
    }
}

@media only screen and (max-width: 540px) {
    .header {
        font-size: 32px;
    }
}


button {
    font-family: 'Poppins', sans-serif !important;
}

@media only screen and (max-width: 1100px) {
    button {
        min-width: 50px !important;
    }
}

.d-flex {
    display: flex;
}

.align-item-center {
    align-items: center;
}

.mr-30{
    margin-right: 30px;
}

.mr-15 {
    margin-right: 15px !important;
}

.fs-18 {
    font-size: 18px;
}

.content {
    padding-left: 25vw;
    padding-right: 25vw;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 1840px) {
    .content {
        padding-left: 22vw;
        padding-right: 22vw;
    }
}

@media only screen and (max-width: 1650px) {
    .content {
        padding-left: 20vw;
        padding-right: 20vw;
    }
}

@media only screen and (max-width: 1540px) {
    .content {
        padding-left: 15vw;
        padding-right: 15vw;
    }
}

@media only screen and (max-width: 1320px) {
    .content {
        padding-left: 10vw;
        padding-right: 10vw;
    }
}

.Toastify {
    font-size: 14px;
}

.bold {
    font-weight: 700;
}