

.fw-600 {
    font-weight: 600 !important;
}

.navbar-item{
    font-size: 16px !important;
}

@media only screen and (max-width: 1320px) {
    .navbar-item{
        font-size: 14px !important;
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
}

@media only screen and (max-width: 1100px) {
    .navbar-item{
        font-size: 12px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        min-width: 50px !important;
    }
}

.color-black {
    color: black !important;
}

.narrow-item {
    display: block !important;
}

.nav-menu-narrow-icon {
    z-index: 10;
    text-align: center;
}

.menu-icon {
    color: black;
}

@media only screen and (max-width: 950px) {
    .nav-menu-narrow {
        display: block;
    }
}

@media only screen and (min-width: 950px) {
    .nav-menu-narrow {
        display: none;
    }
}

.nav-menu-narrow-content {
    text-align: center;
    background-color: #E1987C;
}

.open-menu .nav-menu-narrow-content {
    display: block;
}

.close-menu .nav-menu-narrow-content{
    display: none;
}

.nav-menu-narrow-wrap {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 10px;
    z-index: 10;
}




