.connect-button {
    background-color: #E1987C !important;
    color: black !important;
    margin-left: 40px !important;
    font-weight: 600 !important;
    z-index: 20;
}

@media only screen and (max-width: 1320px) {
    .connect-button {
        margin-left: 10px !important;
    }
}

@media only screen and (max-width: 1100px) {
    .connect-button {
        font-size: 12px !important;
    }
}

@media only screen and (max-width: 540px) {
    .connect-button {
        font-size: 10px !important;
    }
}