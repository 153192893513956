.footer {
    background-color: #ABABAB;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 600px) {
    .footer {
        text-align: center;
    }
}

.footer-header {
    font-size: 26px;
    font-weight: 400;
}

@media only screen and (max-width: 800px) {
    .footer-header {
        font-size: 20px;
        font-weight: 400;
    }
}

.footer-content {
    margin-top: 20px;
    font-size: 16px;
    display: grid;
}

@media only screen and (max-width: 800px) {
    .footer-content {
        font-size: 14px;
        display: grid;
    }
}

.footer-content a{
    text-decoration: none;
    color: black;
}

.copyright {
    font-size: 12px;
    text-align: center;
    padding-top: 50px;
}