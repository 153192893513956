.main-hero {
    background-image: url("../../../../assets/images/Header_Background/MintingsitebackgroundTOP.jpg");
    background-position: top right;
}

.container {
    margin-left: 15vw;
    margin-right: 15vw;
    padding-top: 65px;
    padding-bottom: 65px;
}

@media only screen and (max-width: 1560px) {
    .container {
        margin-left: 10vw;
        margin-right: 10vw;
    }
}

@media only screen and (max-width: 1100px) {
    .container {
        margin-left: 3vw;
        margin-right: 3vw;
        padding-bottom: 40px;
    }
}

.main-title {
    font-size: 70px;
    font-weight: 700;
    text-align: center;
    color: #E1987C;
    padding-top: 65px;
    padding-bottom: 30px;
    text-shadow: 9px 7px 10px rgb(0, 0, 0, 0.7);
}

@media only screen and (max-width: 1560px) {
    .main-title {
        padding-top: 30px;
    }
}

@media only screen and (max-width: 1320px) {
    .main-title {
        font-size: 60px;
    }
}

@media only screen and (max-width: 1100px) {
    .main-title {
        font-size: 48px;
    }
}

@media only screen and (max-width: 834px) {
    .main-title {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

@media only screen and (max-width: 540px) {
    .main-title {
        font-size: 32px;
    }
}

.mint-button-group-wrapper {
    display: flex;
    justify-content: center;
}

.mint-button-group {
    display: flex ;
    justify-content: space-evenly;
    padding-top: 35px;
    padding-bottom: 45px;
    width: 70%;
}

@media only screen and (max-width: 900px) {
    .mint-button-group {
        justify-content: space-between;
    }
}

@media only screen and (max-width: 834px) {
    .mint-button-group {
        justify-content: space-evenly;
    }
}

@media only screen and (max-width: 1100px) {
    .mint-button-group {
        width: 50%;
    }
}

@media only screen and (max-width: 834px) {
    .mint-button-group {
        width: 70%;
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 800px) {
    .mint-button-group {
        display: block;
        padding-bottom: 0px;
    }
}

.mint-button-wrap {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .mint-button-wrap {
        margin-bottom: 20px;
    }
}

.mint-button {    
    background-color: #E1987C !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-size: 28px !important;
    color: black !important;
}

@media only screen and (max-width: 1320px) {
    .mint-button {
        font-size: 24px !important;
    }
}

@media only screen and (max-width: 1100px) {
    .mint-button {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 540px) {
    .mint-button {
        font-size: 14px !important;
    }
}

.mint-button span {
    font-weight: 700 !important;
}

.mint-info {
    display: flex;
    margin-top: 65px;
    justify-content: center;
    /* font-size: 21px; */
    text-align: center;
}

@media only screen and (max-width: 1560px) {
    .mint-info {
        margin-top: 45px;
    }
}

@media only screen and (max-width: 1320px) {
    .mint-info {
        margin-top: 30px;
    }
}

.mint-info-content {
    background-color: white;
    padding: 10px;
    width: 1050px;
}

@media only screen and (max-width: 1320px) {
    .mint-info-content {
        width: 850px;
    }
}

@media only screen and (max-width: 1100px) {
    .mint-info-content {
        width: 760px;
    }
}

.mint-info-content a {
    color: black;
    /* text-decoration: none; */
}